import React from 'react';
import { Helmet } from 'react-helmet';
import '../Styles/Policy.css';

const PrivacyPolicy = () => {
  const lastUpdated = "November 21, 2024";

  const policyStructuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Privacy Policy",
    "description": "Cognora's privacy policy explaining how we protect user data and maintain privacy in our AI-powered educational platform.",
    "publisher": {
      "@type": "Organization",
      "name": "Cognora"
    },
    "dateModified": new Date().toISOString()
  };

  return (
    <>
      <Helmet>
        <title>Privacy Policy | Cognora</title>
        <meta name="description" content="Learn how Cognora protects your privacy and handles data across our AI educational platforms. Read our comprehensive privacy policy." />
        <meta name="keywords" content="Cognora privacy policy, AI education privacy, student data protection, learning platform security" />
        <link rel="canonical" href="https://cognora.ca/privacy-policy" />
        
        <script type="application/ld+json">
          {JSON.stringify(policyStructuredData)}
        </script>
      </Helmet>
      <div className="policy-container">
        <div className="policy-header">
          <h1>Privacy Policy</h1>
          <p className="policy-meta">Last Updated: {lastUpdated}</p>
        </div>

        <div className="policy-toc">
          <h3>Table of Contents</h3>
          <ul>
            <li><a href="#overview">1. Overview</a></li>
            <li><a href="#collection">2. Information Collection</a></li>
            <li><a href="#usage">3. Information Usage</a></li>
            <li><a href="#sharing">4. Information Sharing</a></li>
            <li><a href="#security">5. Data Security</a></li>
            <li><a href="#ai-training">6. AI Processing & Third-Party Services</a></li>
            <li><a href="#rights">7. Your Rights & Choices</a></li>
            <li><a href="#international">8. International Data Transfers</a></li>
            <li><a href="#compliance">9. Legal Compliance</a></li>
            <li><a href="#children">10. Children's Privacy</a></li>
            <li><a href="#changes">11. Policy Changes</a></li>
            {/* <li><a href="#contact">12. Contact Information</a></li> */}
          </ul>
        </div>

        <section id="overview" className="policy-section">
          <h2>1. Overview</h2>
          <p>Cognora Inc. ("Cognora," "we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our educational technology services, including StudyBuddy and related platforms ("Services").</p>
          <p>We serve users globally and comply with international data protection standards, including GDPR, CCPA, PIPEDA, and other applicable regulations.</p>
        </section>

        <section id="collection" className="policy-section">
          <h2>2. Information Collection</h2>
          
          <h3>2.1 Information You Provide</h3>
          <ul>
            <li>Account information (name, email, academic details)</li>
            <li>Profile information and preferences</li>
            <li>Payment information (processed via Stripe)</li>
            <li>Communications with our AI systems</li>
            <li>Uploaded documents and study materials</li>
            <li>Feedback and support communications</li>
          </ul>

          <h3>2.2 Automatically Collected Information</h3>
          <ul>
            <li>Device information (browser type, IP address)</li>
            <li>Usage patterns and interaction data</li>
            <li>Performance and error data</li>
            <li>Location information (country/region)</li>
          </ul>

          <h3>2.3 Third-Party Sources</h3>
          <p>We may receive information about you from:</p>
          <ul>
            <li>Educational institutions (with proper authorization)</li>
            <li>Integration partners</li>
            <li>Authentication services</li>
            <li>Public sources for research and verification</li>
          </ul>
        </section>

        <section id="usage" className="policy-section">
          <h2>3. Information Usage</h2>
          
          <h3>3.1 Core Service Provision</h3>
          <ul>
            <li>Delivering personalized AI tutoring</li>
            <li>Processing and analyzing educational content</li>
            <li>Managing your account and preferences</li>
            <li>Processing payments and subscriptions</li>
          </ul>

          <h3>3.2 Service Improvement</h3>
          <ul>
            <li>Enhancing AI model performance</li>
            <li>Developing new educational features</li>
            <li>Analyzing usage patterns and trends</li>
            <li>Conducting research and development</li>
          </ul>

          <h3>3.3 Communication</h3>
          <ul>
            <li>Service updates and announcements</li>
            <li>Technical support and assistance</li>
            <li>Educational content and resources</li>
            <li>Marketing communications (with consent)</li>
          </ul>
        </section>

        <section id="sharing" className="policy-section">
          <h2>4. Information Sharing</h2>
          
          <h3>4.1 Service Providers</h3>
          <p>We share information with trusted service providers:</p>
          <ul>
            <li>Cloud infrastructure providers</li>
            <li>Payment processors (Stripe)</li>
            <li>Analytics services</li>
            <li>Customer support tools</li>
          </ul>

          <h3>4.2 Legal Requirements</h3>
          <p>We may disclose information:</p>
          <ul>
            <li>To comply with legal obligations</li>
            <li>To protect rights and safety</li>
            <li>To prevent fraud or abuse</li>
            <li>In response to valid legal requests</li>
          </ul>
        </section>

        <section id="security" className="policy-section">
          <h2>5. Data Security</h2>
          <p>We implement comprehensive security measures:</p>
          <ul>
            <li>Encryption in transit and at rest</li>
            <li>Access controls and authentication</li>
            <li>Regular security assessments</li>
            <li>Employee training and compliance</li>
            <li>Incident response procedures</li>
          </ul>
        </section>

        <section id="ai-training" className="policy-section">
          <h2>6. AI Processing & Third-Party Services</h2>
          <p>Our AI-powered features are provided through:</p>
          <ul>
            <li>Anthropic's Claude AI</li>
            <li>OpenAI's GPT models</li>
            <li>Google's Gemini</li>
            <li>Groq's cloud infrastructure</li>
          </ul>
          <p>These services process your queries and content in real-time. We do not maintain or train our own AI models. All AI processing is handled by our trusted third-party providers in accordance with their respective privacy policies and security standards.</p>
        </section>

        <section id="rights" className="policy-section">
          <h2>7. Your Rights & Choices</h2>
          
          <h3>7.1 Access Rights</h3>
          <p>You have the right to:</p>
          <ul>
            <li>Access your personal information</li>
            <li>Request data portability</li>
            <li>Correct inaccurate data</li>
            <li>Request data deletion</li>
          </ul>

          <h3>7.2 Control Options</h3>
          <ul>
            <li>Manage notification preferences</li>
            <li>Control data sharing settings</li>
            <li>Opt-out of marketing communications</li>
            <li>Delete account and associated data</li>
          </ul>

          <h3>7.3 Exercise Your Rights</h3>
          <p>To exercise these rights, you can:</p>
          <ul>
            <li>Use account settings and privacy controls</li>
            <li>Contact our privacy team at privacy@cognora.ca</li>
            <li>Submit a formal rights request through our portal</li>
          </ul>
        </section>

        <section id="international" className="policy-section">
          <h2>8. International Data Transfers</h2>
          
          <h3>8.1 Data Storage Locations</h3>
          <p>We primarily store data in:</p>
          <ul>
            <li>Canada (primary data center)</li>
            <li>United States (backup and processing)</li>
            <li>European Union (regional services)</li>
          </ul>

          <h3>8.2 Transfer Safeguards</h3>
          <p>We protect international transfers through:</p>
          <ul>
            <li>Standard Contractual Clauses (SCCs)</li>
            <li>Data Processing Agreements (DPAs)</li>
            <li>Privacy Shield compliance (where applicable)</li>
            <li>Regional data localization (when required)</li>
          </ul>
        </section>

        <section id="compliance" className="policy-section">
          <h2>9. Legal Compliance</h2>
          
          <h3>9.1 Regulatory Frameworks</h3>
          <p>We comply with:</p>
          <ul>
            <li>GDPR (European Union)</li>
            <li>CCPA (California)</li>
            <li>PIPEDA (Canada)</li>
            <li>COPPA (Children's Privacy)</li>
            <li>FERPA (Educational Records)</li>
          </ul>

          <h3>9.2 Industry Standards</h3>
          <ul>
            <li>ISO 27001 Information Security</li>
            <li>SOC 2 Type II Compliance</li>
            <li>Cloud Security Alliance</li>
          </ul>
        </section>

        <section id="children" className="policy-section">
          <h2>10. Children's Privacy</h2>
          
          <h3>10.1 Age Restrictions</h3>
          <p>Our services have specific age requirements:</p>
          <ul>
            <li>13+ for basic account creation</li>
            <li>Parental consent required for users under 16</li>
            <li>Special protections for educational institution users</li>
          </ul>

          <h3>10.2 Special Protections</h3>
          <p>For users under 18, we:</p>
          <ul>
            <li>Collect minimal personal information</li>
            <li>Require parental/guardian consent</li>
            <li>Provide enhanced privacy controls</li>
            <li>Restrict marketing communications</li>
          </ul>
        </section>

        <section id="changes" className="policy-section">
          <h2>11. Policy Changes</h2>
          
          <h3>11.1 Update Process</h3>
          <p>When we update this policy, we will:</p>
          <ul>
            <li>Post the new version on our website</li>
            <li>Update the "Last Modified" date</li>
            <li>Notify users of significant changes</li>
            <li>Obtain consent when required</li>
          </ul>

          <h3>11.2 Previous Versions</h3>
          <p>Previous versions of this policy are available upon request. Contact our privacy team for access to historical versions.</p>
        </section>

        {/* <section id="contact" className="policy-section">
          <h2>12. Contact Information</h2>
          
          <h3>12.1 Privacy Team</h3>
          <p>For privacy-related inquiries:</p>
          <ul>
            <li>Email: privacy@cognora.com</li>
            <li>Phone: +1 (XXX) XXX-XXXX</li>
            <li>Address: [Company Address]</li>
          </ul>

          <h3>12.2 Data Protection Officer</h3>
          <p>Our DPO can be reached at:</p>
          <ul>
            <li>Email: dpo@cognora.com</li>
            <li>Office Hours: Monday-Friday, 9 AM - 5 PM EST</li>
          </ul>
        </section> */}

        <div className="policy-footer">
          <p>This Privacy Policy was last updated on {lastUpdated}.</p>
          <p>For questions or concerns about our privacy practices, please contact us at privacy@cognora.ca</p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy; 