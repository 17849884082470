import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../Styles/Blog.css';
import blog1Image from '../../assets/blog1.jpg';
import blog2Image from '../../assets/blog2.jpg';

export const blogPosts = [
  {
    id: 1,
    title: "StudyBuddy: Where AI Meets Education - Transforming How You Learn",
    date: "2024-11-01",
    displayDate: "November 1st, 2024",
    author: {
      name: "Founder, Cognora",
      bio: "Founder and AI researcher at Cognora, passionate about transforming education through AI innovation."
    },
    category: "Product Release",
    tags: ["AI Education", "EdTech", "Product Launch", "StudyBuddy"],
    excerpt: "Discover StudyBuddy, the AI-powered learning companion that's transforming education through personalized learning experiences and innovative study tools.",
    imageUrl: blog1Image,
    lastModified: "2024-03-15",
    content: `
# StudyBuddy: Where AI Meets Education

The landscape of education is evolving, and today we're excited to announce StudyBuddy - an AI-powered learning companion that adapts to your unique learning journey. Gone are the days of one-size-fits-all tutoring and rigid learning paths.

Every student faces unique challenges: complex topics that need clarification at midnight, the struggle to organize study materials effectively, or simply needing a patient tutor to explain concepts differently. StudyBuddy addresses these challenges head-on with a suite of innovative features.

## Key Features That Transform Learning

### Smart Study Resources & Version Control
Keep your study materials organized and accessible. StudyBuddy doesn't just store your notes - it understands them. With built-in version control, track your learning progress and never lose important revisions.

### Adaptive Chat Modes
Switch seamlessly between different learning approaches:
- Q&A Mode for quick answers
- Tutor Mode for in-depth explanations
- Brainstorm Mode for creative problem-solving
- Debate Mode to challenge your understanding

### Personalized Learning Experience
StudyBuddy's User Memory System learns your preferences, adapts to your learning style, and remembers your progress. The more you interact, the more personalized your experience becomes.

### Integrated Learning Tools
Access comprehensive study support with:
- PDF document analysis
- Real-time web search integration
- Advanced rendering for complex subjects
- Code display support for programming topics

This initial release marks just the beginning of our journey. We're committed to continuous improvement and expansion of StudyBuddy's capabilities, with regular updates and new features planned for the coming months.

**Join us in transforming the future of education.**
    `
  },
  {
    id: 2,
    title: "Study Spaces: Breaking Down Complex Learning Into Manageable Steps",
    date: "2024-11-22",
    displayDate: "November 22nd, 2024",
    author: {
      name: "Founder, Cognora",
      bio: "Founder and AI researcher at Cognora, passionate about transforming education through AI innovation."
    },
    category: "Feature Release",
    tags: ["Study Spaces", "Feature Release", "Learning Tools", "Educational Innovation"],
    excerpt: "Introducing Study Spaces, a revolutionary feature that transforms how you tackle complex learning challenges by providing structured, organized environments for in-depth study.",
    imageUrl: blog2Image,
    lastModified: "2024-11-22",
    content: `
# Study Spaces: Breaking Down Complex Learning Into Manageable Steps

We're excited to announce Study Spaces, a powerful new feature that transforms how you tackle complex learning challenges. While single conversations are great for quick questions, real learning often requires a more structured approach.

## Real-World Learning Scenarios

### Computer Science & Programming
Imagine learning a new programming language:
- **Day 1**: Upload language documentation and start with basic syntax
- **Day 2**: Return to practice functions and data structures
- **Day 3**: Build upon previous concepts to understand object-oriented programming
- **Day 4**: Work through practical coding exercises
- **Day 5**: Review and reinforce challenging concepts

Instead of losing context between sessions or scrolling through endless chat history, Study Spaces keeps everything organized and connected.

### Medical Studies
For medical students studying anatomy:
- Upload Gray's Anatomy PDFs and lecture notes
- Create separate conversations for each body system
- Reference previous discussions when exploring connected topics
- Track understanding of interconnected biological processes
- Build comprehensive study guides over time

### Mathematics & Physics
When mastering calculus:
- Begin with limits and continuity
- Progress to derivatives while referencing previous concepts
- Upload practice problems and worked solutions
- Create dedicated discussions for challenging topics
- Connect theoretical concepts with practical applications

## Why Complex Learning Needs Structure

Traditional single-chat approaches fall short because:
1. Complex topics build upon previous understanding
2. Learning requires different types of interactions at different stages
3. Reference materials need to be easily accessible
4. Progress needs to be tracked and measured
5. Context from previous sessions is crucial for advancement

## How Study Spaces Transforms Your Learning

### Maintained Context Across Sessions
Instead of starting fresh with each conversation, Study Spaces maintains the context of your learning journey. Your AI tutor understands the materials you've uploaded and the concepts you've covered, allowing for more sophisticated and relevant interactions over time.

### Organized Learning Progression
- **Resource Management**: Upload and reference study materials across multiple conversations
- **Connected Discussions**: Link related topics and build upon previous explanations
- **Progress Tracking**: Monitor your advancement through complex topics

## Availability

Study Spaces is available now for Pro and Ultimate tier subscribers, offering a sophisticated solution for managing complex learning tasks and long-term study projects.

## Getting Started

Ready to transform how you tackle complex learning? Create your first Study Space:
1. Log into your Pro or Ultimate account
2. Navigate to the Study Spaces section
3. Click "New Space" to create your learning environment
4. Start organizing your learning journey more effectively

Join us in revolutionizing how complex topics are mastered. Create your first Study Space today and experience a more coherent, organized, and effective way to learn.
    `
  }
];

const BlogPost = () => {
  const { id } = useParams();
  const post = blogPosts.find(post => post.id === parseInt(id));

  if (!post) {
    return (
      <div className="blog-post-container">
        <h1>Blog Post Not Found</h1>
        <Link to="/blog" className="return-button">
          ← Back to Blog
        </Link>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{post.title} | Cognora Blog</title>
        <meta name="description" content={post.excerpt} />
        <meta name="keywords" content={post.tags.join(", ")} />
        <link rel="canonical" href={`https://cognora.ca/blog/${post.id}`} />
        
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": post.title,
            "image": post.imageUrl,
            "datePublished": post.date,
            "dateModified": post.lastModified,
            "author": {
              "@type": "Person",
              "name": post.author.name,
              "description": post.author.bio
            },
            "publisher": {
              "@type": "Organization",
              "name": "Cognora",
              "logo": {
                "@type": "ImageObject",
                "url": "https://cognora.ca/logo.png"
              }
            },
            "description": post.excerpt,
            "keywords": post.tags.join(", "),
            "articleBody": post.content
          })}
        </script>
      </Helmet>

      <div className="blog-post-container">
        <div className="blog-card-image full">
          <img src={post.imageUrl} alt={post.title} />
        </div>
        <article className="blog-post">
          <Link to="/blog" className="return-button">
            ← Back to Blog
          </Link>
          <header className="blog-post-header">
            <div className="blog-post-meta-top">
              <span className="blog-post-category">{post.category}</span>
              <time dateTime={post.date}>{post.displayDate}</time>
            </div>
            <h1>{post.title}</h1>
            <div className="blog-tags">
              {post.tags.map(tag => (
                <span key={tag} className="tag">{tag}</span>
              ))}
            </div>
            <div className="blog-post-author">
              <div className="author-info">
                <span className="author-name">By {post.author.name}</span>
                <p className="author-bio">{post.author.bio}</p>
              </div>
            </div>
          </header>

          <div className="blog-post-content">
            <ReactMarkdown>{post.content}</ReactMarkdown>
          </div>
          
          <div className="blog-post-cta">
              <Link to="https://studdybuddy.ca/" className="cta-button">
                Meet StudyBuddy - Your New Learning Companion
              </Link>
            </div>

          <footer className="blog-post-footer">
            <div className="share-section">
              <h3>Share this article</h3>
              <div className="share-buttons">
                <a 
                  href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(post.title)}&url=${encodeURIComponent(window.location.href)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="share-button"
                >
                  <i className="fab fa-twitter"></i>
                  Twitter
                </a>
                <a 
                  href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(post.title)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="share-button"
                >
                  <i className="fab fa-linkedin"></i>
                  LinkedIn
                </a>
                <a 
                  href={`mailto:?subject=${encodeURIComponent(post.title)}&body=${encodeURIComponent(window.location.href)}`}
                  className="share-button"
                >
                  <i className="fas fa-envelope"></i>
                  Email
                </a>
              </div>
            </div>

          </footer>
        </article>
      </div>
    </>
  );
};

export default BlogPost; 