import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './styles/variables.css';
import './App.css';
import LandingPage from './Components/LandingPage';
import Footer from './Components/Partials/Footer';
import TermsofService from './Components/Info Pages/TermsofService';
import PrivacyPolicy from './Components/Info Pages/PrivacyPolicy';
import Blog from './Components/Blog';
import BlogPost from './Components/Data/BlogPost';
import NavBar from './Components/Partials/NavBar';
import FAQ from './Components/Info Pages/FAQ';
import Contact from './Components/Contact';
import StudyBuddyLanding from './Components/StudyBuddy/StudyBuddyLanding';
import CookiesPage from './Components/Pages/CookiesPage';
import About from './Components/Pages/About';

// Scroll to top component
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// Add color scheme detection
function App() {
  const [theme, setTheme] = useState(() => {

    // Always return light theme
    return 'light';
  });

  useEffect(() => {
    // Listen for system theme changes
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    
    const handleChange = (e) => {
      const newTheme = e.matches ? 'dark' : 'light';
      // Only auto-switch if there's no saved preference
      if (!localStorage.getItem('theme')) {
        setTheme(newTheme);
        document.documentElement.setAttribute('data-theme', newTheme);
      }
    };

    mediaQuery.addEventListener('change', handleChange);
    
    // Set initial theme
    document.documentElement.setAttribute('data-theme', theme);

    return () => mediaQuery.removeEventListener('change', handleChange);
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
    localStorage.setItem('theme', newTheme);
  };

  return (
    <Router>
      <div className="app-container">
        <ScrollToTop />
        <NavBar theme={theme} toggleTheme={toggleTheme} />
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route path="/terms-of-service" element={<TermsofService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogPost />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />
          <Route path='/studybuddy' element={<StudyBuddyLanding />} />
          <Route path='/cookies' element={<CookiesPage />} />
          <Route path='/about' element={<About />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
