import React from 'react';
import { Link } from 'react-router-dom';
import './Styles/LandingPage.css';
import { FaArrowRight } from 'react-icons/fa';
import useDocumentTitle from '../UseDocumentTitle';
import { blogPosts } from './Data/BlogPost';
import { Helmet } from 'react-helmet';

const LandingPage = () => {
  useDocumentTitle('Cognora');

  const structuredData = `{
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Cognora",
    "description": "AI-powered educational technology pioneering personalized learning solutions",
    "url": "https://cognora.ca",
    "sameAs": [
      "https://twitter.com/CognoraAI",
      "https://linkedin.com/company/cognora"
    ],
    "knowsAbout": [
      "Artificial Intelligence",
      "Educational Technology",
      "Machine Learning",
      "Personalized Learning"
    ]
  }`;

  const recentPosts = blogPosts.slice(0, 2);

  return (
    <>
    <Helmet>
    <title>Cognora</title>
    <meta name="description" content="Cognora pioneers AI-powered education technology that adapts to every learner. Experience StudyBuddy, our intelligent learning companion." />
    <meta name="keywords" content="Cognora, Cognora AI, AI education, artificial intelligence learning, StudyBuddy, AI tutor, personalized learning, educational technology, AI teaching assistant, machine learning education, intelligent tutoring system, adaptive learning platform, AI-powered study tools, educational AI, smart learning companion, virtual tutor, AI curriculum assistant, cognitive learning technology, natural language tutoring, contextual learning AI, real-time learning feedback, AI study optimization, educational data analytics, student success platform, AI learning paths, interactive AI education, knowledge acceleration platform" />
    <link rel="canonical" href="https://cognora.ca/" />
    
    {/* Open Graph tags */}
    <meta property="og:title" content="Cognora | AI-Powered Educational Innovation" />
    <meta property="og:description" content="Experience the future of personalized learning with Cognora's AI-powered educational technology." />
    
    {/* Structured data as a string */}
    <script type="application/ld+json">
      {structuredData}
    </script>
  </Helmet>

    <div className="landing-container">
      <main className="hero-section">
        <h1>
          <span className="highlight">AI-powered education</span> that adapts to every learner
        </h1>
        <p className="hero-text">
          Building the future of personalized learning through advanced artificial intelligence.
        </p>
        <div className="cta-buttons">
          <Link to="https://studdybuddy.ca/" className="primary-cta">Try StudyBuddy</Link>
          <Link to="/studybuddy" className="secondary-cta">Learn More</Link>
        </div>
      </main>

      <section className="blog-preview-section animate-in">
        <h2 className="section-title">Latest from Our Blog</h2>
        <div className="blog-preview-grid">
          {recentPosts.map(post => (
            <article key={post.id} className="blog-preview-card">
              <div className="blog-preview-image">
                <img src={post.imageUrl} alt={post.title} />
              </div>
              <div className="blog-preview-content">
                <span className="blog-preview-category">{post.category}</span>
                <h3>{post.title}</h3>
                <p>{post.excerpt}</p>
                <div className="blog-preview-footer">
                  <span className="blog-preview-date">{post.displayDate}</span>
                  <Link to={`/blog/${post.id}`} className="blog-preview-link">
                    Read More <FaArrowRight />
                  </Link>
                </div>
              </div>
            </article>
          ))}
        </div>
        <div className="blog-preview-cta">
          <Link to="/blog" className="blog bl-cta-button">
            View All Posts <FaArrowRight />
          </Link>
        </div>
      </section>

      <section className="features-grid">
        {/* <div className="feature-card">
          <h3>Personalized Learning</h3>
          <p>Experience education tailored to your unique learning style and pace with our AI technology.</p>
          <Link to="/about" className="learn-more">Learn More →</Link>
        </div> */}
        <div className="feature-card">
          <h3>Our Mission</h3>
          <p>Transforming education through innovative AI solutions and research.</p>
          <Link to="/about" className="learn-more">About Us →</Link>
        </div>
      </section>

      <section className="about-section">
        <div className="about-content">
          <h2>Transforming Education Through AI</h2>
          <p>
            Cognora is dedicated to making quality education accessible to everyone. Our team combines decades of 
            educational expertise with cutting-edge AI technology to create 
            learning experiences that truly understand and adapt to each student.
          </p>
        </div>
      </section>

      <section className="values-section">
        <h2>Our Values</h2>
        <div className="values-grid">
          <div className="value-card">
            <h3>Innovation</h3>
            <p>Pushing the boundaries of what's possible in educational technology.</p>
          </div>
          <div className="value-card">
            <h3>Accessibility</h3>
            <p>Making quality education available to learners everywhere.</p>
          </div>
          <div className="value-card">
            <h3>Excellence</h3>
            <p>Committed to the highest standards in both education and technology.</p>
          </div>
        </div>
      </section>

      <section className="products-section" id="products">
        <h2>Our Solutions</h2>
        <div className="product-showcase">
          <div className="product-card">
            <h3>StudyBuddy</h3>
            <p>Our flagship AI-powered learning companion, designed to revolutionize how students learn and understand complex topics.</p>
            <Link to="/studybuddy" className="learn-more">Learn More →</Link>
          </div>
          <div className="product-card research">
            <h3>Research & Development</h3>
            <p>Exploring new frontiers in AI education technology to shape the future of learning.</p>
            {/* <Link to="/research" className="learn-more">Discover More →</Link> */}
            <span className="learn-more">Coming Soon →</span>
          </div>
        </div>
      </section>

      <section className="site-navigation" style={{display: 'none'}}>
        <h2>Quick Links</h2>
        <nav className="quick-links">
          <Link to="/about" aria-label="Learn About Cognora">About Us</Link>
          <Link to="/blog" aria-label="Read Our Blog">Blog</Link>
          <Link to="/faq" aria-label="View Frequently Asked Questions">FAQ</Link>
          <Link to="/privacy-policy" aria-label="Read Privacy Policy">Privacy</Link>
          <Link to="/terms-of-service" aria-label="View Terms of Service">Terms</Link>
        </nav>
      </section>
    </div>
    </>
  );
};

export default LandingPage;
